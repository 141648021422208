<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <base-heading title="Roles"/>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols="12">
				<base-data-table 
					v-model="sortBy"
					:headers="headers" 
					:items="roles"
					hideNewItemButton
					showEnabledField
					@loadDataTable="toList"
        >
				</base-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
			sortBy: 'visualName',
			roles: [],
			headers: [
        { text: "Nombre", value: "visualName" },
        { text: "Descripción", value: "description", sortable: false},
        { text: "Estado", value: "enabled", sortable: false}
      ],
		}
	},

	created() {
		this.toList();
  },

	methods: {
		async toList(){
			let me=this;
			try {
        me.SHOW_LOADING()
				me.roles=[];
				await me.getObjectResponse('api/Role/GetActiveRoles', me.baseRequest).then(data => {
					if (data !== undefined) {
						me.roles = data.roles; 
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
	},
}
</script>